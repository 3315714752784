<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template>

    <v-divider class="mb-1" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <div class="pt-5 pb-2 pl-3">
          <v-img
              v-if="color == '#0058A5'"
              src="@/assets/microplan.png"
             width="15em"
          />
          <v-img
              v-if="color == '#1D325E'"
              src="@/assets/psc.png"
              width="12em"
          />
          <v-img
              v-if="color == '#D66A2B'"
              src="@/assets/virl.png"
              width="12em"
          />
          <v-img
              v-if="color == '#221E63'"
              src="@/assets/fundhouse.png"
              width="12em"
          />
          <v-img
              v-if="color == '#BC9722'"
              src="@/assets/ashleen_pic.png"
              width="12em"
          />
          <v-img
              v-if="color == '#005CB0'"
              src="@/assets/fbc.png"
              width="12em"
          />
          <v-img
              v-if="color == '#005CB9'"
              src="@/assets/xarani-nobg.png"
              width="15em"
          />
          <v-img
              v-if="color == '#FF1C0F'"
              src="@/assets/redsphere.jpg"
              width="15em"
          />
          <v-img
              v-if="color == '#A77A02'"
              src="@/assets/african_century.png"
              width="15em"
          />
        </div>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',
    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      color:'',
      ssb_items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/user/dashboard',
        },
        // {
        //   icon: 'mdi-file-arrow-up-down-outline',
        //   title: 'All bookings',
        //   to: '/user/all-bookings',
        // },
        // {
        //   icon: 'mdi-file-document-multiple-outline',
        //   title: 'All batches',
        //   to: '/user/all-batch',
        // },
        {
          icon: 'mdi-license',
          title: 'Upload Statement',
          to: '/user/upload-statement',
        },
        // {
        //   icon: 'mdi-license',
        //   title: 'Provisional Responses',
        //   to: '/user/dashboard3',
        // },
        // {
        //   icon: 'mdi-license',
        //   title: 'Monthly Statements',
        //   to: '/user/dashboard5',
        // },
        // {
        //   icon: 'mdi-license',
        //   title: 'Invoices',
        //   to: '/user/dashboard6',
        // },
        // {
        //   icon: 'mdi-license',
        //   title: 'Payments',
        //   to: '/user/dashboard7',
        // },
        // {
        //   icon: 'mdi-account',
        //   title: 'Agency',
        //   to: '/branch/agency',
        // },
      ],
      bss_items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/user/dashboard-bss',
        },
        {
          icon: 'mdi-account-plus',
          title: 'Manage Users',
          to: '/user/add-user',
        },
        {
          icon: 'mdi-account-plus',
          title: 'Manage Codes',
          to: '/user/add-codes',
        },
        {
          icon: 'mdi-file-multiple',
          title: 'View Records',
          to: '/user/bss-records',
        },
        {
          icon: 'mdi-account-clock',
          title: 'Organisation Count',
          to: '/user/bss-orgcount',
        },
        // {
        //   icon: 'mdi-file-clock-outline',
        //   title: 'Manage Invoices',
        //   to: '/user/bss-invoices',
        // },
      ],
      accounts_items: [
        {
          icon: 'mdi-file-clock-outline',
          title: 'Manage Invoices',
          to: '/user/bss-invoices',
        },
        {
          icon: 'mdi-file-clock-outline',
          title: 'Generate Invoices',
          to: '/user/finance-generate-invoice',
        },
      ],
      agent_items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/user/dashboard',
        },
        {
          icon: 'mdi-upload-outline',
          title: 'Bulk Booking',
          to: '/user/booking',
        },
        {
          icon: 'mdi-account-arrow-up-outline',
          title: 'Individual Booking',
          to: '/user/ibooking',
        },
        {
          icon: 'mdi-file-clock-outline',
          title: 'Batch Records',
          to: '/user/batch',
        },
        {
          icon: 'mdi-clipboard-file-outline',
          title: 'Records',
          to: '/user/records',
        },
        {
          icon: 'mdi-clipboard-file-outline',
          title: 'Monthly Statements',
          to: '/user/statement',
        },
        {
          icon: 'mdi-license',
          title: 'Invoices',
          to: '/user/billing',
        },
        // {
        //   icon: 'mdi-license',
        //   title: 'Provisional Responses',
        //   to: '/user/dashboard3',
        // },
        // {
        //   icon: 'mdi-license',
        //   title: 'Monthly Statements',
        //   to: '/user/dashboard5',
        // },
        // {
        //   icon: 'mdi-license',
        //   title: 'Invoices',
        //   to: '/user/dashboard6',
        // },
        // {
        //   icon: 'mdi-license',
        //   title: 'Payments',
        //   to: '/user/dashboard7',
        // },
        // {
        //   icon: 'mdi-account',
        //   title: 'Agency',
        //   to: '/branch/agency',
        // },
      ],
    }),
    computed: {
      ...mapState(['barColor', 'barImage', 'is_manager', 'is_bss', 'is_fbcmanager','approved_color','institution', 'is_agent','is_accounts']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        if (this.$store.state.is_ssb) {
          return  this.ssb_items.map(this.mapItem)
        }
        else if (this.$store.state.is_accounts) {
          return  this.accounts_items.map(this.mapItem)
        }
        else if (this.$store.state.is_bss) {
          return  this.bss_items.map(this.mapItem)
        }
        else  {
          return this.agent_items.map(this.mapItem)
        }

      },
      profile () {
        return {
          avatar: true,
          title: this.$t('avatar'),
        }
      },
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
    created () {
      this.color = this.approved_color
      console.log(this.color)
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'
  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
